
import { defineComponent, ref, reactive, computed, Ref } from 'vue';
import ServiceDependDialog from './Depend.vue';
import _ from 'lodash';
import { ElMessage } from 'element-plus';
import { distributeRepository, getServiceVersions } from '@/api/repository';
import { getAllProjectList } from '@/api/project';
import { getTenantDetail } from '@/api/tenant';
import { getServiceShowName } from '@/views/service-management/components/utils';
import { userCurrentProject } from '@/layout/messageCenter/user-info';

export default defineComponent({
  name: 'DistributeDialog',
  components: {
    ServiceDependDialog,
  },
  props: {
    refresh: {
      type: Function,
      default: _.noop,
    },
  },
  setup(props) {
    const visible = ref(false);
    const submitting = ref(false);
    const formRef = ref(null as any);
    const serviceDependDialog = ref(null as any);
    const sourceData = ref(null as any);
    const projectList = ref([] as any[]);
    const serviceVersionsList = ref([] as any[]);
    const currentProject = ref(null as any);
    const tenant = ref(null as any);
    const initPlatformShareType: Ref<number> = ref(2);
    const form = reactive({
      repositoryId: '',
      projectId: '',
      platformShareType: 2,
      rename: 0,
      serviceName: '',
      serviceNameZh: '',
      skuType: 1,
    });
    const fetchTenant = async () => {
      const { data } = await getTenantDetail();
      tenant.value = data;
    };

    const useNewName = computed(() => form.platformShareType === 2 && form.rename === 1);
    // 获取项目列表
    const fetchProjectList = async () => {
      const { data } = await getAllProjectList({});
      // 要过滤启用的状态和删除的状态
      projectList.value = data.filter((item: any) => item.status === 1 && item.delState === 0);
    };
    const fetchServiceVersions = async () => {
      const param = {
        skuType: sourceData.value.skuType,
        serviceName: sourceData.value.skuName,
      };
      const { data } = await getServiceVersions(param);
      // 要过滤启用的状态
      serviceVersionsList.value = data.filter((item: any) => item.status === 1);
      console.log('serviceVersionsList', serviceVersionsList.value);
    };
    const handleClose = () => {
      visible.value = false;
      formRef.value.resetFields();
    };
    const handleOpen = (row: any) => {
      visible.value = true;
      sourceData.value = row;
      submitting.value = false;
      form.serviceName = getServiceShowName(row.skuName);
      form.serviceNameZh = row.skuNameZh;
      form.platformShareType = sourceData.value.platformShareType;
      if (form.platformShareType === 3) {
        form.platformShareType = 1;
      }
      form.skuType = sourceData.value.skuType;
      initPlatformShareType.value = sourceData.value.platformShareType;
      fetchServiceVersions();
      fetchTenant();
      fetchProjectList();
    };
    const handleSubmit = async () => {
      try {
        submitting.value = true;
        const valid = await formRef.value.validate();
        if (!valid) {
          return;
        }
        const postData: any = {
          platformShareType: form.platformShareType,
          projectId: form.projectId,
          repositoryId: form.repositoryId,
        };
        // 需要重命名
        if (form.platformShareType === 2 && form.rename === 1) {
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          postData.serviceName = newServiceFullName.value;
          postData.serviceNameZh = form.serviceNameZh;
        } else {
          postData.serviceName = sourceData.value.skuName;
          postData.serviceNameZh = sourceData.value.skuNameZh;
        }
        await distributeRepository(postData);
        ElMessage.success('服务下发成功');
        props.refresh && props.refresh();
        handleClose();
      } catch (e) {
        console.log(111, e);
      } finally {
        submitting.value = false;
      }
    };
    const handleClearValidate = () => {
      formRef.value.clearValidate(['serviceName', 'serviceNameZh']);
    };
    const handleViewServiceDepend = () => {
      serviceDependDialog.value.handleOpen(sourceData.value);
    };

    // 新名全名
    const newServiceFullName = computed(() => {
      if (useNewName.value && currentProject.value) {
        if (form.platformShareType === 2 && form.skuType === 1) {
          return `app.${tenant.value.tenantEngAbbr}.${currentProject.value.name}.${form.serviceName}`;
        }
        return `public.${tenant.value.tenantEngAbbr}.${currentProject.value.name}.${form.serviceName}`;
      }
      return '请选择项目';
    });
    const handleProjectChange = (projectId: number) => {
      const project = projectList.value.find((item) => item.id === projectId);
      currentProject.value = project;
    };

    const validatorEnName = (rule: any, value: any, callback: any) => {
      const reg = /^(?!-)(?!.*-$)[a-z0-9-]+$/;
      if (!reg.test(value)) {
        callback(new Error(rule.message));
      } else {
        callback();
      }
    };
    return {
      visible,
      submitting,
      form,
      formRef,
      serviceDependDialog,
      handleOpen,
      handleClose,
      handleSubmit,
      handleClearValidate,
      handleViewServiceDepend,
      sourceData,
      projectList,
      serviceVersionsList,
      useNewName,
      tenant,
      newServiceFullName,
      handleProjectChange,
      validatorEnName,
      initPlatformShareType,
      userCurrentProject,
    };
  },
});
