
import { defineComponent, ref, reactive } from 'vue';
import { ElMessage } from 'element-plus';
import ServiceDependDialog from './Depend.vue';
import { shareRepository } from '@/api/repository';
import _ from 'lodash';
export default defineComponent({
  name: 'SharedDialog',
  components: {
    ServiceDependDialog,
  },
  props: {
    refresh: {
      type: Function,
      default: _.noop,
    },
  },
  setup(props) {
    const visible = ref(false);
    const submitting = ref(false);
    const formRef = ref(null as any);
    const serviceDependDialog = ref(null as any);
    const sourceData = ref(null as any);
    const form = reactive({
      platformShareType: [0],
      platformShareDescription: '',
      skuType: 1,
    });
    const sharedTypes = reactive([
      {
        value: 1,
        label: '引用',
      },
      {
        value: 2,
        label: '克隆',
      },
    ]);
    const formRules = reactive({
      platformShareType: [{ required: true, message: '请选择共享方式', trigger: 'blur' }],
      platformShareDescription: [{ max: 1024, message: '最多输入1024个字符', trigger: 'blur' }],
    });
    const handleClose = () => {
      visible.value = false;
      formRef.value.resetFields();
    };
    const handleOpen = (row: any) => {
      sourceData.value = row;
      visible.value = true;
      submitting.value = false;
      form.skuType = sourceData.value.skuType;
      switch (row.platformShareType) {
        case 1:
          form.platformShareType = [1];
          break;
        case 2:
          form.platformShareType = [2];
          break;
        case 3:
          form.platformShareType = [1, 2];
          break;
        default:
          form.platformShareType = [];
      }
    };
    const platformShareTypeChange = (value: number[]) => {
      switch (sourceData.value.platformShareType) {
        case 1:
          if (value.indexOf(sourceData.value.platformShareType) < 0) {
            if (form.platformShareType[0]) {
              form.platformShareType.push(form.platformShareType[0]);
            }
            form.platformShareType[0] = 1;
          }
          break;
        case 2:
          if (value.indexOf(sourceData.value.platformShareType) < 0) {
            if (form.platformShareType[0]) {
              form.platformShareType.push(form.platformShareType[0]);
            }
            form.platformShareType[0] = 2;
          }
          break;
        case 3:
          form.platformShareType = [1, 2];
          break;
        default:
      }
    };
    const handleSubmit = async () => {
      try {
        submitting.value = true;
        const valid = await formRef.value.validate();
        if (!valid) {
          return;
        }
        const platformShareType = form.platformShareType.length > 1 ? 3 : Number(form.platformShareType.join(''));
        await shareRepository({
          platformShareType,
          platformShareDescription: form.platformShareDescription,
          storeId: sourceData.value.id,
        });
        ElMessage.success('服务共享成功');
        props.refresh && props.refresh();
        handleClose();
      } catch (e) {
        console.log(e);
      } finally {
        submitting.value = false;
      }
    };
    const handleClearValidate = () => {
      formRef.value.clearValidate(['serviceName', 'serviceNameZh']);
    };
    const handleViewServiceDepend = () => {
      serviceDependDialog.value.handleOpen(sourceData.value);
    };
    return {
      visible,
      submitting,
      form,
      formRules,
      formRef,
      serviceDependDialog,
      handleOpen,
      handleClose,
      handleSubmit,
      handleClearValidate,
      handleViewServiceDepend,
      sharedTypes,
      sourceData,
      platformShareTypeChange,
    };
  },
});
