
import { computed, defineComponent, reactive, ref } from 'vue';
import { ElMessageBox, ElMessage } from 'element-plus';
import { useRoute } from 'vue-router';
import DistributeDialog from '../components/dialog/Distribute.vue';
import SharedDialog from '../components/dialog/Shared.vue';
import { getRepositoryList, pullRepository } from '@/api/repository';
import { getAllTags } from '@/api/settings/tags';
import { getClassificationList } from '@/api/settings/classification';
import { userInfo } from '@/layout/messageCenter/user-info';
import { SERVICE_LEVEL, getSharedType, getServiceSource } from './config';
import { filterClassificationList, getClassificationName, getTagsName } from '../util';
import { getShowBool } from '@/utils/permission-show-module';
import { useSaList } from '@/shared/hooks/list';

export default defineComponent({
  components: {
    DistributeDialog,
    SharedDialog,
  },
  props: {
    listType: {
      type: String,
    },
    skuType: {
      type: String,
    },
  },
  name: 'ServiceRepositoryList',
  setup(props) {
    const sharedDialogRef = ref(null as any);
    const distributeDialogRef = ref(null as any);
    const classificationList = ref([] as any);
    const tagList = ref([] as any);
    const route = useRoute();

    const extendQuery = reactive({
      classification: '',
      tags: [],
      status: null,
      auditResults: null,
      sortField: 'createTime',
      sortType: 'descending',
    });

    let platformType = 0;
    switch (props.listType) {
      case 'platform':
        platformType = 0;
        break;
      case 'shared':
        platformType = 1;
        break;
      case 'distribute':
        platformType = 2;
        break;
    }
    let skuType = 0;
    switch (props.skuType) {
      case '1':
        skuType = 1;
        break;
      case '2':
        skuType = 2;
        break;
    }

    const baseRoutePath = computed(() => {
      if (props.listType === 'platform') {
        return '/service-repository/platform';
      }
      return `/service-repository/tenant/${props.listType}`;
    });

    const { loading, list, total, query, handleSearch, handlePageChange, handlePageSizeChange, fetchList } = useSaList(
      async (query: any) => {
        loading.value = true;
        try {
          const { rows, count } = (
            await getRepositoryList({
              ...extendQuery,
              ...query,
              platformType,
              skuType,
            })
          ).data;
          // eslint-disable-next-line no-restricted-syntax
          for (const i in rows) {
            if (rows[i].tenantPermission) {
              rows[i].platformShareType = rows[i].tenantPermission;
            }
          }
          list.value = rows;
          total.value = count;
        } catch (e) {
          console.log(e);
        }
        loading.value = false;
      },
    );

    const fetchAllData = async () => {
      loading.value = true;
      try {
        const [classificationRes, tagsRes] = await Promise.all([getClassificationList(), getAllTags(), fetchList()]);
        classificationList.value = filterClassificationList(classificationRes.data);
        tagList.value = tagsRes.data;
      } catch (e) {
        console.log(e);
      }
      loading.value = false;
    };

    const classification: any = reactive({
      visible: false,
      props: {
        label: 'name',
        value: 'id',
        emitPath: false,
      },
      handleChange(value: string) {
        extendQuery.classification = value;
      },
    });

    const tagFilter: any = reactive({
      visible: false,
    });

    function handleCloseFilterOverlay() {
      classification.visible = false;
      tagFilter.visible = false;
      query.page = 1;
      fetchList();
    }

    const handleShare = (row: any) => {
      if (row.platformShareType === 3) {
        return;
      }
      // eslint-disable-next-line no-param-reassign
      row.skuType = skuType;
      sharedDialogRef.value.handleOpen(row);
    };

    const handleDistribute = (row: any) => {
      // eslint-disable-next-line no-param-reassign
      row.skuType = skuType;
      distributeDialogRef.value.handleOpen(row);
    };

    const handlePull = (row: any) => {
      ElMessageBox.confirm(
        `将该服务从平台仓库拉取至租户仓库后，租户内的项目可按照该服务提供的相应权限进行开发。`,
        '确定拉取至租户',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: '',
        },
      ).then(async () => {
        await pullRepository({
          storeId: row.id,
          tenantPermission: row.platformShareType,
        });
        ElMessage.success('拉取至租户仓库成功');
        fetchList();
      });
    };
    fetchAllData();
    return {
      loading,
      list,
      total,
      query,
      extendQuery,
      handleSearch,
      handlePageChange,
      handlePageSizeChange,
      fetchList,
      getSharedType(row: any) {
        if (row.platformShareType === 0 && props.listType === 'shared') {
          return '未共享';
        }
        return getSharedType(row.platformShareType);
      },
      handleShare,
      handlePull,
      handleDistribute,
      sharedDialogRef,
      distributeDialogRef,
      serviceLevels: {
        ...SERVICE_LEVEL,
      },
      route,
      classification,
      classificationList,
      tagList,
      tagFilter,
      handleCloseFilterOverlay,
      platformType,
      getTagsName,
      getClassificationName,
      getServiceSource,
      userInfo,
      getShowBool,
      baseRoutePath,
    };
  },
});
