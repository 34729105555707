
import { defineComponent, ref, nextTick } from 'vue';
import ServiceDepend from '../depend/Index.vue';
export default defineComponent({
  name: 'DependDialog',
  components: {
    ServiceDepend,
  },
  setup() {
    const visible = ref(false);
    const isApp = ref(false);
    const serviceDependRef = ref(null as any);
    const handleClose = () => {
      visible.value = false;
    };
    const handleOpen = (service: any) => {
      visible.value = true;
      isApp.value = service.skuType === 1;
      nextTick(() => {
        console.log('传递参数', service);
        serviceDependRef.value.render(
          service.skuName,
          service.skuLatestVersion,
          service.skuType === 1 ? 'app' : 'service',
        );
      });
    };
    return {
      visible,
      isApp,
      handleOpen,
      handleClose,
      serviceDependRef,
    };
  },
});
